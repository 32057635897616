import {PageRouteHandlers} from "./main.resources";
import {SharedPageBindingsComponent} from "./components/pages/index/shared-page-bindings.component";

function bootStrapStatic(){
    /*
    const routeHandler = PageRouteHandlers.find(e =>location.pathname ===e.url);
    if(routeHandler){
        routeHandler.handlers.forEach(h => new (h as any)(routeHandler));
    }

     */

    redirectToWww()
    //console.log(location.pathname, routeHandler);
}
function redirectToWww(){
    if(location.host.indexOf('www') > -1|| location.host.indexOf('localhost') > -1){
        return;
    }
    location.href = 'https://www.100daysofianda.com';
}
bootStrapStatic()